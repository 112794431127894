var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',{staticClass:"bg-body-color main"},[_c('div',{staticClass:"wrapper__auth-new",staticStyle:{"justify-content":"flex-start"}},[_c('div',{staticClass:"auth__logo",on:{"click":_vm.redirectToGeorApp}},[_c('img',{attrs:{"src":require("@/assets/images/logo.svg"),"alt":"Geor, registro"}})]),_c('div',{staticClass:"auth__box"},[_c('span',{staticClass:"title__auth"},[_vm._v("Crear cuenta")]),_c('p',{staticClass:"auth__box-p"},[_vm._v("Ingresa tus datos para crear tu cuenta.")]),_c('ValidationObserver',{ref:"formRegister",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{attrs:{"autocomplete":"on"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('ValidationProvider',{attrs:{"name":"Tu primer nombre","vid":"full_name","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Tu primer nombre","type":"text"},model:{value:(_vm.form.full_name),callback:function ($$v) {_vm.$set(_vm.form, "full_name", $$v)},expression:"form.full_name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Correo eletrónico","vid":"email","rules":"required|email|max:190"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Correo eletrónico","type":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Contraseña","vid":"password","rules":"required|min:8|password_strong|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Contraseña","error-messages":errors,"type":_vm.showPassword ? 'text' : 'password'},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.showPassword)?_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("@/assets/images/icon-eye.svg"),"alt":"Ojo abierto"},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}}):_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("@/assets/images/icon-eye-close.svg"),"alt":"Ojo cerrado"},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}})]},proxy:true}],null,true),model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('v-btn',{staticClass:"v-btn-pad-x-large btn-new",staticStyle:{"border-radius":"5px!important"},attrs:{"disabled":invalid || _vm.loading,"color":"primary","loading":_vm.loading,"depressed":"","block":"","type":"submit"}},[_c('span',{staticClass:"text-button-new"},[_vm._v("Continuar")])]),_c('div',{staticStyle:{"padding-top":"24px"}},[_c('p',{staticClass:"text-left auth__mw",staticStyle:{"margin-bottom":"0px !important"}},[_vm._v(" Al continuar acepta los "),_c('a',{staticClass:"text-decoration-underline",staticStyle:{"color":"$primary"},attrs:{"href":"https://geor.app/terminos"}},[_vm._v("Términos y Condiciones")]),_vm._v(" y "),_c('a',{staticClass:"text-decoration-underline",staticStyle:{"color":"$primary"},attrs:{"href":"https://geor.app/privacidad"}},[_vm._v("Políticas de Privacidad ")]),_vm._v("de Geor ")])])],1)]}}])})],1),_c('p',{staticClass:"mb-5",staticStyle:{"padding-top":"4px !important","font-size":"14px !important"}},[_vm._v(" ¿Ya tienes una cuenta? "),_c('router-link',{staticClass:"auth__link color-primary",staticStyle:{"text-decoration":"underline"},attrs:{"to":{ name: 'LoginEmail' }}},[_vm._v("Inicia sesión. ")])],1),_c('br')])])}
var staticRenderFns = []

export { render, staticRenderFns }