<template>
  <v-main class="bg-body-color main">
    <div class="wrapper__auth-new"  style="justify-content: flex-start;">
      <div class="auth__logo" @click="redirectToGeorApp">
        <img src="@/assets/images/logo.svg" alt="Geor, registro" />
      </div>
      <div class="auth__box">
        <span class="title__auth">Crear cuenta</span>
        <p class="auth__box-p">Ingresa tus datos para crear tu cuenta.</p>
        <ValidationObserver v-slot="{ invalid }" tag="div" ref="formRegister">
          <form @submit.prevent="onSubmit" autocomplete="on">
            <!-- <ValidationProvider name="país" rules="required" v-slot="{ errors }">
              <geor-select-countries
                :countries="countries"
                :errors="errors"
                v-model="form.country_id"
              ></geor-select-countries>
            </ValidationProvider> -->
            <ValidationProvider
              name="Tu primer nombre"
              vid="full_name"
              rules="required|max:100"
              v-slot="{ errors }"
            >
              <v-text-field
                :error-messages="errors"
                v-model="form.full_name"
                label="Tu primer nombre"
                type="text"
              ></v-text-field>
            </ValidationProvider>
            <ValidationProvider
              name="Correo eletrónico"
              vid="email"
              rules="required|email|max:190"
              v-slot="{ errors }"
            >
              <v-text-field
                :error-messages="errors"
                v-model="form.email"
                label="Correo eletrónico"
                type="email"
              ></v-text-field>
            </ValidationProvider>
            <ValidationProvider
              name="Contraseña"
              vid="password"
              rules="required|min:8|password_strong|max:50"
              v-slot="{ errors }"
            >
              <v-text-field v-model="form.password"
                label="Contraseña" :error-messages="errors" :type="showPassword ? 'text' : 'password'">
                    <template v-slot:append>
                      <img v-if="showPassword" src="@/assets/images/icon-eye.svg" alt="Ojo abierto" @click="showPassword = !showPassword" style="cursor: pointer;">
                      <img v-else src="@/assets/images/icon-eye-close.svg" alt="Ojo cerrado" @click="showPassword = !showPassword" style="cursor: pointer;">
                    </template>
                  </v-text-field>
            </ValidationProvider>
            <v-btn
              :disabled="invalid || loading"
              color="primary"
              :loading="loading"
              depressed
              block
              class="v-btn-pad-x-large btn-new"
              type="submit"
              style="border-radius: 5px!important;"
              ><span class="text-button-new">Continuar</span></v-btn
            >
            <!-- <br /><br /><br />
                <router-link :to="{ name: 'Register' }" :disabled="loading">
                  Quiero registrarme usando mi número de teléfono
                </router-link> -->

              <div style="padding-top: 24px;">
                <p class="text-left auth__mw" style="margin-bottom: 0px !important;">
Al continuar acepta los
        <a class="text-decoration-underline"
          :href="`https://geor.app/terminos`" style="color: $primary; !important"
          >Términos y Condiciones</a> y
        <a :href="`https://geor.app/privacidad`"
          class="text-decoration-underline" style="color: $primary;
          !important">Políticas de Privacidad
          </a>de Geor
      </p>
              </div>
          </form>
        </ValidationObserver>
      </div>
      <p class="mb-5" style="padding-top: 4px !important; font-size: 14px !important;">
        ¿Ya tienes una cuenta?
        <router-link class="auth__link color-primary" style="text-decoration:underline;" :to="{ name: 'LoginEmail' }"
          >Inicia sesión.
        </router-link>
      </p>
      <!-- <BtnWhatsapp /> -->
      <br />

    </div>
  </v-main>
</template>

<script>
// import SelectCountries from "@/components/partials/SelectCountries.vue";
import { mapState } from "vuex";
// import BtnWhatsapp from "../components/BtnWhatsapp.vue";

export default {
  components: {
    // BtnWhatsapp,
    //   "geor-select-countries": SelectCountries,
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_URL_WEB,
      showPassword: false,
      form: {
        country_id: "PE",
        full_name: "",
        email: "",
        password: "",
      },
    };
  },
  computed: {
    ...mapState({
      // countries: (state) => state.countries,
      loading: (state) => state.loading,
    }),
  },
  mounted() {
    // if (this.countries.length === 0) {
    //   this.$store.dispatch("LOAD_COUNTRIES");
    // }
  },
  methods: {
    onSubmit() {
      this.$axios
        .post("auth/register", this.form)
        .then((response) => {
          const { data } = response;
          const token = this.$auth.generateToken(data.data.token_type, data.data.access_token);
          this.$auth.saveUser(data.data.user);
          this.$store.dispatch("ON_LOGIN", token);
          this.$router.push({ name: "Dashboard" });

          // this.$router.push({
          //   name: "CheckCode",
          //   query: {
          //     type: "email",
          //     email: this.form.email,
          //     country_id: this.form.country_id,
          //   },
          // });
        })
        .catch((error) => this.showErrors(error, "formRegister"));
    },
    redirectToGeorApp() {
      // Redirecciona a la ruta externa
      window.location.href = 'https://geor.app/';
    },
  },
};
</script>
